<template>
  <div>
    <b-container fluid class="p-2">
      <div class="p-2">
        <b-button-toolbar key-nav aria-label="Fatura İşlemler">
          <b-button to="/Fatura/0" variant="success" class="mx-1">Yeni Oluştur</b-button>
          <b-button v-b-toggle.filtreleriAc variant="primary">Filtrele</b-button>
          <b-button-group class="mx-1" v-if="totalRows > 0" right>
            <download-excel
              :data="excelExportList"
              :footer="excelFooter"
              :header="excelHeader"
              stringifyLongNum:true
              :fields="excelSutunlar"
              escapeCsv:false
              name="fatura Listesi.xls"
            >
              <b-button>Excel</b-button>
            </download-excel>
            <b-button @click="faturaIndir">İndir</b-button>
            <b-button @click="listedekileriYazdir">Toplu Yazdır</b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>

      <b-collapse id="filtreleriAc">
        <b-card title="Filtreler">
          <div class="row">
            <div class="col-12 col-xl-4 col-lg-4">
              Cari Ad
              <b-form-input
                v-model="filtreler.cariAd"
                placeholder="Cari Ad"
              ></b-form-input>
            </div>
            <div class="col-12 col-xl-1 col-lg-1">
              Ay
              <b-form-select
                v-model="filtreler.ay"
                class="form-control"
                :options="aylar"
              ></b-form-select>
            </div>
            <div class="col-12 col-xl-2 col-lg-2">
              Tip
              <b-form-select
                v-model="filtreler.tip"
                class="form-control"
                :options="faturaTipler"
              ></b-form-select>
            </div>
            <div class="col-12 col-xl-2 col-lg-2">
              Senaryo
              <b-form-select
                v-model="filtreler.senaryo"
                class="form-control"
                :options="eFatSenaryolar"
              ></b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-2 col-lg-2">
              Başlangıç Tarihi
              <b-form-datepicker
                v-model="filtreler.trh1"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                locale="tr"
              ></b-form-datepicker>
            </div>

            <div class="col-12 col-xl-2 col-lg-2">
              Bitiş Tarihi
              <b-form-datepicker
                v-model="filtreler.trh2"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                locale="tr"
              ></b-form-datepicker>
            </div>

            <div class="col-12 col-xl-2 col-lg-2">
              Kategori
              <b-form-input
                v-model="filtreler.kategori"
                placeholder="Kategori"
              ></b-form-input>
            </div>
            <div class="col-12 col-xl-2 col-lg-2">
              Tutardan Büyük
              <b-form-input v-model="filtreler.tutar1" placeholder="Tutar"></b-form-input>
            </div>
            <div class="col-12 col-xl-2 col-lg-2">
              Tutardan Küçük
              <b-form-input v-model="filtreler.tutar2" placeholder="Tutar"></b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 col-xl-6">
              Stok/Hizmet
              <b-form-input
                v-model="filtreler.stokHizmet"
                placeholder="Stok/Hizmet"
              ></b-form-input>
            </div>
             <div class="col-12 col-lg-6 col-xl-6">
              Belge No
              <b-form-input
                v-model="filtreler.faturaNo"
                placeholder="Belge No"
              ></b-form-input>
            </div>
          </div>
        </b-card>
      </b-collapse>

      <b-card header="Faturalar" header-tag="header">
        <template #header>
          <h6 class="mb-0">Faturalar</h6>
          <h4 class="text-muted">
            Fatura Yönetim <b-badge class="badge alert-info m-2"
              >{{ totalRows }} Adet Fatura</b-badge
            >
          </h4>
          <p>
            <span v-for="(veri, index) in faturaToplamAdetler" v-bind:key="veri[index]"
              ><b-badge class="badge alert-info"
                >{{ veri.tip }} {{ veri.adet }} Adet</b-badge
              ></span
            >
          </p>
        </template>
        <div v-show="totalRows > 0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            pills
          ></b-pagination>
        </div>
        <b-table
          hover
          responsive
          :items="invitationsFiltered"
          :fields="fatColumns"
          :current-page="currentPage"
          :per-page="perPage"
          show-empty
          label-sort-clear
          label-sort-asc
          label-sort-desc
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          small
          sort-icon-left
          empty-filtered-text="Kayıt Bulunamadı"
          empty-text="Kayıt Bulunamadı"
        >
          <template #cell(faturaTip)="data">
            {{ faturaTipler.find((o) => o.value === data.value).text }}
          </template>
          <template #cell(eFatSenaryo)="data">
            {{ eFatSenaryolar.find((o) => o.value === data.value).text }}
          </template>
          <template #cell(faturaTarihi)="data">
            {{ data.value | moment("DD.MM.YYYY hh:ss") }}
          </template>
          <template #cell(toplamTutar)="data">
            <div class="paraKolonu ortaKolonUzunluk">
              {{ $store.getters.formatPrice(data.item.toplamTutar) }}
              {{ data.item.dovizCinsi }}

              <div v-if="data.item.dovizCinsi != 'TL'">
                {{ data.item.toplamTutar * data.item.dovizKuru }} TL
                <p>Kur : {{ data.item.dovizKuru }}</p>
              </div>
            </div>
          </template>
          <template #cell(vadeTarihi)="data">
            <span v-if="Date(data.item.ozelVadeTarihi) > Date(data.value)">{{
              data.item.ozelVadeTarihi | moment("DD.MM.YYYY hh:ss")
            }}</span>
            <span v-else>{{ data.value | moment("DD.MM.YYYY hh:ss") }}</span>

            <p>
              <b-badge
                variant="warning"
                class="badge alert-warning"
                v-if="data.item.odendi == false && vadeGeldimi(data.item.vadeTarihi)"
              >
                Vadesi Geldi</b-badge
              >
            </p>
          </template>

          <template #cell(faturaNo)="data">
            {{ data.value }}
            <b-button
              variant="primary"
              v-b-tooltip
              title="Fatura Detay"
              v-b-modal="data.item.faturaNo"
              class="border-0 bg-transparent"
            >
              <b-icon icon="exclamation-circle-fill" variant="secondary"></b-icon
            ></b-button>
            <b-modal
              :id="data.item.faturaNo"
              size="xl"
              hide-footer
              hide-header-close
              title="Fatura Detay"
            >
              <b-table
                :items="data.item.kalemler"
                :fields="hizliDetayColumns"
                show-empty
                label-sort-clear
                label-sort-asc
                label-sort-desc
                :sort-by.sync="sortByModal"
                :sort-desc.sync="sortDesc"
                small
                sort-icon-left
                header
                responsive
              >
                <template #cell(satirToplam)="data">
                  <div class="paraKolonu">
                    {{ $store.getters.formatPrice(data.value) }}
                  </div>
                </template>
                <template #cell(bFiyat)="data">
                  <div class="paraKolonu">
                    {{ $store.getters.formatPrice(data.value) }}
                  </div>
                </template>
              </b-table>
            </b-modal>

            <p>
              <b-badge
                variant="warning"
                class="badge alert-success"
                v-if="
                  data.item.faturaTip == '1' && data.item.odendi
                    ? true
                    : data.item.faturaTip == '0' && data.item.odendi
                    ? true
                    : false
                "
              >
                {{
                  data.item.faturaTip == "1" && data.item.odendi
                    ? "Ödendi"
                    : data.item.faturaTip == "0" && data.item.odendi
                    ? "Tahsil Edildi"
                    : ""
                }}
              </b-badge>
            </p>

            <p v-if="data.item.kategorileri">
              <b-badge
                variant="info"
                class="badge alert-info m-1"
                v-for="(kat, index) in data.item.kategorileri.split(',')"
                v-bind:key="kat[index]"
              >
                {{ kat }}
              </b-badge>
            </p>
            <p v-if="data.item.proforma">
            <b-badge variant="info">PROFORMA</b-badge>
            </p>
          </template>
          <template #cell(gibDurum)="data" class="col-2">
            <b-button
              v-b-tooltip
              v-if="data.item.faturaTip != '1' && data.item.faturaTip != '2'"
              :title="data.item.gibDurum"
              variant="link"
            >
              <b-icon icon="exclamation-circle-fill" variant="secondary"></b-icon>
            </b-button>
          </template>
          <template #cell(actions)="data">
            <b-dropdown right>
              <b-dropdown-item
                target="_blank"
                v-bind:href="
                  $store.state.portalFaturalarAdres +
                  '/' +
                  (data.item.gorsel == null
                    ? data.item.faturaGuid + '.pdf'
                    : data.item.gorsel)
                "
                >Göster</b-dropdown-item
              >

              <b-dropdown-item
                @click="belgeDurumSorgula(data.item.Id)"
                v-if="data.item.gibeGitti == true && data.item.onlineGeldi == false"
                >Portal Durum Sorgula</b-dropdown-item
              >
              <b-dropdown-item
                @click="fatGorselDegistir(data.item.Id)"
                v-if="data.item.faturaTip == '1' && data.item.faturaTip != '2'"
                >Görseli Değiştir</b-dropdown-item
              >
                <b-dropdown-item

                v-if="data.item.faturaTip == '1' && data.item.faturaTip != '2'"
                >İADE Fatura Oluştur</b-dropdown-item
              >
              <b-dropdown-item @click="faturaHizliDuzenleAc(data.item)"
                >Bilgi Düzenle</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  data.item.onlineGeldi == false &&
                  (data.item.faturaTip != '1' || data.item.faturaTip != '2')
                "
                >Mail Gönder</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  data.item.odendi == false &&
                  data.item.onlineGeldi == false &&
                  (data.item.faturaTip != '1' || data.item.faturaTip != '2')
                "
                >K.K.Tahsilat Link Gönder</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item
                v-if="
                  data.item.onlineGeldi == false &&
                  data.item.odendi == false &&
                  (data.item.faturaTip != '1' || data.item.faturaTip != '2')
                "
                >Nakit Tahsilat</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  data.item.onlineGeldi == false &&
                  data.item.odendi == false &&
                  (data.item.faturaTip != '1' || data.item.faturaTip != '2')
                "
                >Çek Tahsilat</b-dropdown-item
              >
              <b-dropdown-item
                @click="nakitOdemeAlAc"
                v-if="
                  data.item.odendi == false &&
                  (data.item.faturaTip == '1' || data.item.faturaTip == '2')
                "
                >Nakit Ödeme</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  data.item.odendi == false &&
                  (data.item.faturaTip == '1' || data.item.faturaTip == '2')
                "
                >Çek Ödeme</b-dropdown-item
              >

              <b-dropdown-item
                v-if="data.item.gibeGitti == true && data.item.eFatSenaryo == '0'"
                >E-Arşiv İptal</b-dropdown-item
              >
              <b-dropdown-item
                v-if="data.item.gibeGitti == false && data.item.onlineGeldi == false"
                >Düzenle</b-dropdown-item
              >
              <b-dropdown-item v-if="data.item.gibeGitti == false">Sil</b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.gibeGitti == false && data.item.onlineGeldi == false"
                >Portala Gönder</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template slot="bottom-row">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="paraKolonu ortaKolonUzunluk" v-html="totalExpenses"></div>
            </td>
            <td></td>
            <td></td>
          </template>
        </b-table>

        <div v-show="totalRows > 0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            pills
            class="my-0"
          ></b-pagination>
        </div>
      </b-card>
    </b-container>

    <b-form-file
      ref="fatGorselDegistirFile"
      v-model="gorselDegistirFile"
      class="d-none"
    ></b-form-file>
  </div>
</template>

<script>

import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/tr");
moment.locale("tr");

export default {
  data() {
    return {
      gorselDegistirFile: null,
      gorselDegistirFatid: 0,
      aylar: [
        { value: undefined, text: "Ay Seçiniz" },
        { value: "0", text: "Ocak" },
        { value: "1", text: "Şubat" },
        { value: "2", text: "Mart" },
        { value: "3", text: "Nisan" },
        { value: "4", text: "Mayıs" },
        { value: "5", text: "Haziran" },
        { value: "6", text: "Temmuz" },
        { value: "7", text: "Ağustos" },
        { value: "8", text: "Eylül" },
        { value: "9", text: "Ekim" },
        { value: "10", text: "Kasım" },
        { value: "11", text: "Aralık" },
      ],
      faturaTipler: [
        { value: undefined, text: "Tip" },
        { value: "0", text: "Satış" },
        { value: "1", text: "Alış" },
        { value: "2", text: "Satış İade" },
        { value: "3", text: "Alış İade" },
        { value: "4", text: "İstisna" },
        { value: "5", text: "İhracat" },
        { value: "15", text: "Tevkifat" },
      ],
      eFatSenaryolar: [
        { value: undefined, text: "Senaryo" },
        { value: "0", text: "E-Arşiv" },
        { value: "1", text: "Temel" },
        { value: "2", text: "Ticari" },
      ],
      sortBy: "faturaTarihi",
      sortByModal: "satisAd",
      sortDesc: true,
      excelExportList: [],
      excelSutunlar: {
        Tip: "tip",
        Senaryo: "senaryo",
        "Fatura Tarihi": "tarih",
        "Vade Tarihi": "vade",
        No: "no",
        Cari: "cari",
        Tutar: "tutar",
      },
      excelFooter: "Monoryum E-Dönüşüm Sistemi",
      excelHeader: "",
      hizliDetayColumns: [
        { key: "satisAd", sortable: true, label: "Ad" },
        { key: "adet", sortable: true, label: "Adet" },
        { key: "bFiyat", sortable: true, label: "B.Fiyat" },
        { key: "satirToplam", sortable: true, label: "Toplam" },
      ],
      fatColumns: [
        { key: "faturaTip", sortable: true, label: "Tip" },
        { key: "eFatSenaryo", sortable: true, label: "Senaryo" },

        { key: "faturaTarihi", sortable: true },
        { key: "vadeTarihi", sortable: true },
        { key: "faturaNo", sortable: true },
        { key: "cariAd", sortable: true },
        { key: "toplamTutar", sortable: true },
        { key: "gibDurum", sortable: false },
        { key: "actions", label: "#" },
      ],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      faturaToplamAdetler: [],
      altToplamlar: [],
      filtreler: {
        cariAd: undefined,
        trh1: new Date(new Date().getFullYear(), 0, 1),
        trh2: new Date(),
        ay: undefined,
        kategori: undefined,
        tip: undefined,
        senaryo: undefined,
        tutar1: undefined,
        tutar2: undefined,
        stokHizmet: "",
        faturaNo:''
      },
    };
  },
  watch: {
    gorselDegistirFile(val) {
      if (!val) return;

      this.fatGorselDegistirYukle();
    },
  },
  methods: {
    belgeDurumSorgula(fatid) {


      //var ind = this.$store.state.tumFaturalar.findIndex((o) => o.Id == fatid);

      //var oid = this.$store.state.tumFaturalar[ind].gibOID;

      
      this.$store.state.loadAnimAc = true;
      var urlsi = "/api/gib/belgeDurumSorgula?fatid=" + fatid + "&belgeTip=FATURA";
      var qs = require("qs");
      var data = qs.stringify({
        userMail: this.$store.state.userMail,
        userSifre: this.$store.state.userSifre,
        sirketId: this.$store.state.calisilanSirketId,
      });
      var config = {
        method: "post",
        url: urlsi,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
           
        },
        data: data,
      };

      var th = this
      axios(config)
        .then((res) => {
          th.$store.state.loadAnimAc = false;
          th.$root.$bvToast.toast(res.data.data, {
            title: `GIB Durum`,
            variant: res.data.sonuc ? "info": "danger",
            solid: true,
            toaster: "b-toaster-top-full",
            autoHideDelay: 5000,
          });
           if (res.data.sonuc) {


                var ind = th.$store.state.tumFaturalar.findIndex(
                  (o) => o.Id == fatid
                );

                th.$store.state.tumFaturalar[ind].gibDurum = res.data.data;

            }


        })
        .catch(function () {
          th.$store.state.loadAnimAc = false;
        });
    
    
    
    
    },
    fatGorselDegistirYukle() {
      var rot = this;

      this.$dialog
        .confirm("Görsel Değiştirilecektir.Onaylıyor musunuz ?", {
          okText: "Onaylıyorum",
          cancelText: "İptal",
        })
        .then(function (dialog) {

          rot.$store.state.loadAnimAc = true;
          var urlsi = "/api/faturasRoute/faturaGorselDegistir";

          var axioss = require("axios");
          var FormData = require("form-data");

          var data = new FormData();
          data.append("File", rot.gorselDegistirFile, rot.gorselDegistirFile.name);
          data.append("ClientDocs", "faturaGorselDegistir");
          data.append("userMail", rot.$store.state.userMail);
          data.append("userSifre", rot.$store.state.userSifre);
          data.append("sirketId", rot.$store.state.calisilanSirketId);
          data.append("fatid", rot.gorselDegistirFatid);

          var config = {
            method: "post",
            url: urlsi,
            headers: {

              "Content-Type": "multipart/form-data",
            },
            data: data,
          };

          axioss(config)
            .then(function (response) {
              /*  console.log(rot.gorselDegistirFatid)
              console.log(rot.$store.state.tumFaturalar) */
              rot.$store.state.loadAnimAc = false;

              rot.$dialog.alert(response.data.mesaj, { okText: "Kapat" });

              if (response.data.sonuc) {
                var ind = rot.$store.state.tumFaturalar.findIndex(
                  (o) => o.Id == rot.gorselDegistirFatid
                );

                rot.$store.state.tumFaturalar[ind].gorsel = response.data.data;
                rot.gorselDegistirFile = null;
                rot.gorselDegistirFatid = 0;
              }
            })
            .catch(function (error) {
              rot.$store.state.loadAnimAc = false;
              console.log(error);
            });
        })
        .catch(function () {
          console.log("Clicked on cancel");
        });
    },
    fatGorselDegistir(fatid) {
      this.gorselDegistirFatid = fatid;

      this.$refs.fatGorselDegistirFile.$el.childNodes[0].click();
    },
    nakitOdemeAlAc() {
      this.$store.state.sideBarTitle = "Nakit Ödeme";
      this.$store.state.sideBarComp = "nakitOdemeAl";
      this.$store.state.sideBarOpen = !this.$store.state.sideBarOpen;
    },
    faturaHizliDuzenleAc(datasi) {
      this.$store.state.faturalar_hizliDuzenleFatura = {
        fatid: datasi.Id,
        fatno: datasi.faturaNo,
        carAd: datasi.cariAd,
        vade: datasi.vadeTarihi,
        kat: datasi.kategorileri,
      };

      this.$store.state.sideBarTitle = "Hızlı Fatura Düzenle";
      this.$store.state.sideBarComp = "faturaHizliDuzenle";
      this.$store.state.sideBarOpen = !this.$store.state.sideBarOpen;
    },
    listedekileriYazdir() {
      var indirliste = [];
      for (let item in this.invitationsFiltered) {
        indirliste.push(this.invitationsFiltered[item].Id);
      }

      var rut = this.$router;
      var rot = this.$store;

      this.$dialog
        .confirm(indirliste.length + " Adet Dosya Yazdırılacaktır.Onaylıyormusunuz", {
          okText: "Onaylıyorum",
          cancelText: "İptal",
        })
        .then(function (dialog) {

          rot.state.loadAnimAc = true;
          var urlsi = "/api/faturasRoute/faturalariYazdir?liste=" + indirliste.join(",");
          var qs = require("qs");
          var data = qs.stringify({
            userMail: rot.state.userMail,
            userSifre: rot.state.userSifre,
            sirketId: rot.state.calisilanSirketId,
          });
          var config = {
            method: "post",
            url: urlsi,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

            },
            data: data,
          };

          axios(config)
            .then((res) => {
              rot.state.loadAnimAc = false;

              window.open(
                rot.state.portalFaturalarAdres + "/" + res.data.mesaj,
                "_blank"
              );
            })
            .catch(function () {
              rot.state.loadAnimAc = false;
            });
        })
        .catch(function () {
          console.log("Clicked on cancel");
        });
    },
   
    vadeGeldimi(vade) {
      let bugun = new Date();
      let gelen = new Date(vade);

      if (gelen < bugun) {
        return true;
      }
    },
    
    faturaIndir() {
      var indirliste = [];
      for (let item in this.invitationsFiltered) {
        indirliste.push(this.invitationsFiltered[item].Id);
      }

      var rut = this.$router;
      var rot = this.$store;

      this.$dialog
        .confirm(indirliste.length + " Adet Dosya indirilecektir.Onaylıyormusunuz", {
          okText: "Onaylıyorum",
          cancelText: "İptal",
        })
        .then(function (dialog) {

          rot.state.loadAnimAc = true;
          var urlsi = "/api/faturasRoute/faturaIndir?liste=" + indirliste.join(",");
          var qs = require("qs");
          var data = qs.stringify({
            userMail: rot.state.userMail,
            userSifre: rot.state.userSifre,
            sirketId: rot.state.calisilanSirketId,
          });
          var config = {
            method: "post",
            url: urlsi,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

            },
            data: data,
          };

          axios(config)
            .then((res) => {
              rot.state.loadAnimAc = false;

              window.open(
                rot.state.portalFaturalarAdres + "/" + res.data.mesaj,
                "_blank"
              );
            })
            .catch(function () {
              rot.state.loadAnimAc = false;
            });
        })
        .catch(function () {
          console.log("Clicked on cancel");
        });
    },
  },

  computed: {
    totalExpenses() {
      var altToplamlarSonuc = [];

      for (let i = 0; i < this.invitationsFiltered.length; i++) {
        altToplamlarSonuc.push({
          key: this.invitationsFiltered[i].dovizCinsi,
          value: this.invitationsFiltered[i].toplamTutar,
          doviZKuru: this.invitationsFiltered[i].doviZKuru,
        });
      }

      var result = [];
      altToplamlarSonuc.reduce(function (res, value) {
        if (!res[value.key]) {
          res[value.key] = { key: value.key, value: parseFloat(0) };
          result.push(res[value.key]);
        }
        res[value.key].value += value.value;

        return res;
      }, {});

      var sonuc = "";
      for (let i = 0; i < result.length; i++) {
        sonuc += "<p>" + this.$store.getters.formatPrice(result[i].value) + " " + result[i].key + "</p>";
      }

      return sonuc;
    },
    invitationsFiltered: function () {
      var filtered = this.$store.state.sirketAyarlar.tumFaturalar;

      this.excelHeader = "";

      if (this.filtreler.ay) {
        filtered = filtered.filter(
          (fl) => new Date(fl.faturaTarihi).getMonth() == this.filtreler.ay
        );
        this.excelHeader +=
          this.aylar.find((o) => o.value == this.filtreler.ay).text + " Ayı Listesi";
      } else {
        if (this.filtreler.trh1) {
          filtered = filtered.filter(
            (fl) =>
              Date.parse(fl.faturaTarihi) >= Date.parse(this.filtreler.trh1) &&
              Date.parse(fl.faturaTarihi) <= Date.parse(this.filtreler.trh2)
          );
        }

        this.excelHeader +=
          moment(this.filtreler.trh1).format("DD.MM.YYYY") +
          " - " +
          moment(this.filtreler.trh2).format("DD.MM.YYYY") +
          " Arası Liste";
      }
      if (this.filtreler.tip) {
        filtered = filtered.filter((lesson) => lesson.faturaTip === this.filtreler.tip);

        this.excelHeader +=
          "<br/>Fatura Tip : " +
          this.faturaTipler.find((o) => o.value == this.filtreler.tip).text;
      }

      if (this.filtreler.cariAd) {
        const regexp = new RegExp(this.filtreler.cariAd, "i");
        filtered = filtered.filter((lesson) => lesson.cariAd.match(regexp));
        this.excelHeader += "<br/>Cari : " + filtered[0].cariAd;
      }
      if (this.filtreler.kategori) {
        const regexp = new RegExp(this.filtreler.kategori, "i");
        filtered = filtered.filter(
          (lesson) => lesson.kategorileri != null && lesson.kategorileri.match(regexp)
        );
        this.excelHeader += "<br/>Kategoriler : " + this.filtreler.kateegori;
      }
      if (this.filtreler.senaryo) {
        filtered = filtered.filter(
          (lesson) => lesson.eFatSenaryo === this.filtreler.senaryo
        );

        this.excelHeader +=
          "<br/>Fatura Senaryo : " +
          this.eFatSenaryolar.find((o) => o.value === this.filtreler.senaryo).text;
      }

      if (this.filtreler.tutar1) {
        filtered = filtered.filter(
          (lesson) =>
            parseFloat(lesson.toplamTutar) >=
              (!isNaN(parseFloat(this.filtreler.tutar1))
                ? parseFloat(this.filtreler.tutar1)
                : 0) &&
            parseFloat(lesson.toplamTutar) <=
              (!isNaN(parseFloat(this.filtreler.tutar2))
                ? parseFloat(this.filtreler.tutar2)
                : 1000000)
        );
      }

      if (this.filtreler.stokHizmet) {
        if (this.filtreler.stokHizmet.length > 3) {
          const regexp = new RegExp(this.filtreler.stokHizmet, "i");
          
          var aranan = this.filtreler.stokHizmet;
          filtered = filtered.filter((eachVal) => {
            let opt = eachVal.kalemler.some(({ satisAd }) => satisAd.match(regexp));
            return opt;
          });
        }
      }
      if (this.filtreler.faturaNo) {
        const regexp = new RegExp(this.filtreler.faturaNo, "i");
        filtered = filtered.filter((lesson) => lesson.faturaNo.match(regexp));

      }
      this.totalRows = filtered.length;

      this.excelExportList = [];

      for (let dt in filtered) {
        this.excelExportList.push({
          tip: this.faturaTipler.find((o) => o.value === filtered[dt].faturaTip).text,
          senaryo: this.eFatSenaryolar.find((o) => o.value === filtered[dt].eFatSenaryo)
            .text,
          tarih: moment(filtered[dt].faturaTarihi).format("DD.MM.YYYY"),
          vade: moment(filtered[dt].vadeTarihi).format("DD.MM.YYYY"),
          no: filtered[dt].faturaNo,
          cari: filtered[dt].cariAd,
          tutar: this.$store.getters.formatPrice(filtered[dt].toplamTutar),
        });
      }

      var altToplamlarSonuc = [];

      for (let i = 0; i < filtered.length; i++) {
        altToplamlarSonuc.push({
          key: filtered[i].dovizCinsi,
          value: filtered[i].toplamTutar,
        });
      }

      var result = [];
      altToplamlarSonuc.reduce(function (res, value) {
        if (!res[value.key]) {
          res[value.key] = { key: value.key, value: parseFloat(0) };
          result.push(res[value.key]);
        }
        res[value.key].value += value.value;

        return res;
      }, {});

      var sonuc = "";
      for (let i = 0; i < result.length; i++) {
        sonuc += "<p>" + this.$store.getters.formatPrice(result[i].value) + " " + result[i].key + "</p>";
      }

      this.excelExportList.push({
        tip: "",
        senaryo: "",
        tarih: "",
        vade: "",
        no: "",
        cari: "Toplam :",
        tutar: sonuc,
      });

      this.faturaToplamAdetler = [];
      for (let k in this.faturaTipler) {
        if (this.faturaTipler[k].value !== undefined) {
          var tip = this.faturaTipler[k].text;
          var tipid = this.faturaTipler[k].value;
          for (let y in this.eFatSenaryolar) {
            if (this.eFatSenaryolar[y].value !== undefined) {
              var adedi = filtered.filter(
                (o) =>
                  o.eFatSenaryo == this.eFatSenaryolar[y].value && o.faturaTip == tipid
              ).length;
              if (adedi > 0) {
                this.faturaToplamAdetler.push({
                  tip: tip + " " + this.eFatSenaryolar[y].text,
                  adet: adedi,
                });
              }
            }
          }
        }

        //
      }

      return filtered;
    },
  },
  created(){
    this.$store.dispatch("sirketFaturalar", { faturaId: 0, gizliAl:false })
  }
};
</script>

<style scoped>

</style>
